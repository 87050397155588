.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
}

.content {
  flex-direction: column;
  width: 1300px;
  display: flex;
  background-color: var(--color-white);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 8px 20px 0px #00000026;
  margin: auto 300px;
}

.iconContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 4px;
  width: 100%;
}


.icon {
  display: flex;
  justify-content: flex-end;
  margin-right: 4px;
  width: 15px;
  cursor: pointer;

}

.iconFilter {
  display: flex;
  justify-content: flex-end;
  margin-right: 4px;
  width: 20px;
}

.iconDownload {
  display: flex;
  margin-left: auto;
  margin-right: 0;
  width: 25px;
  margin-bottom: auto;
  cursor: pointer;
}

.filterContainer {
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 3px solid #f18b24 !important;
  width: 130px;
}

.filterText {
  font-family: Montserrat-SemiBold, sans-serif !important;
  margin-left: 10px !important;
  font-size: 20px !important;
  text-align: left !important;

}

.filterByCategoryText {
  font-family: Montserrat, sans-serif !important;
  font-size: 15px !important;
  text-align: left !important;
  align-self: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.titleCheckContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
}

.filterContainerRow {
  display: flex;
}

.labelCheckActive,
.labelCheckPending,
.labelCheckRefused,
.labelCheckInactive {
  font-size: 18px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;
  padding: 5px 10px;
  gap: 15px;
}

.labelCheckActive {
  color: #71e348;
  border-color: #71e348;

}

.labelCheckPending {
  color: #ee8c38;
  border-color: #ee8c38;

}

.labelCheckRefused {
  color: #cc3136;
  border-color: #cc3136;

}

.labelCheckInactive {
  color: #b6b5b5;
  border-color: #e2e1e1;

}


.checkboxInput {
  display: none;
}

.checkboxImage {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  display: inline-block;
}

.checkboxInput:checked+.checkboxImage {
  opacity: 1;
}

.checkboxInput:not(:checked)+.checkboxImage {
  display: none;
}

.titleRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableContainer td {
  text-align: center;
}