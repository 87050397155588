.avatarWrapper {
  margin: auto 5px;
}

.conversationBlock {
  margin: auto 5px;
  width: 100%;
}

.conversationName {
  margin: 2px auto 1px auto;
  font-size: 20px;
}

.conversationDescription {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 14px;
}

.conversationBody {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
}

.lastMessage {
  margin-top: 0;
}

.conversation {
  width: 99%;
  margin-top: 15px;
  background-color: #ffffff;
  border: 1px solid #68b3e0;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  box-sizing: border-box; 
}

.activeConversation {
  background-color: #F7FBFD;
;
}

.conversationBadge {
  margin: auto 5px;
}
