.avatarWrapper {
  margin: auto 5px;
  flex-shrink: 0;
}

.conversationBlock {
  margin: auto 5px;
  flex-grow: 1;
  min-width: 0;
}

.conversationName {
  margin: 2px auto 1px auto;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversationDescription {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dateWrapper {
  margin: auto 5px;
  flex-shrink: 0;
  white-space: nowrap;
  font-size: 14px;
}

.conversationWall {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #68b3e0;
  box-shadow: 0 2.5px 5px #d8d8d8;
  align-items: center;
}
