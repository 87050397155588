.containerList {
  max-height: 200px;
  overflow-y: auto;
  cursor: pointer;
}

.chatContainer{
    display: flex;
    align-items: center;
    border: 2px solid #68B3E0;
    padding:10px;
    border-radius:10px;
    box-shadow: 0px 4px 4px 0px #00000040 !important;
    cursor: pointer;
    background: white;
    margin-bottom: 0.75rem;
    width: auto;
    padding: .65rem 1rem!important;
    gap: 0.5rem;
    height: 52px;
  }
  
  
  .chatContainer:last-child {
    margin-bottom: 0!important;
  }
  
  
  .listItemText{
    font-family: 'Poppins', sans-serif !important;
    text-transform: capitalize;
  }