.chartContainer {
    display: flex ;
    flex-direction: column ;
    align-items: flex-end ;
    
}
canvas{
    max-height: 300px;
}
.noData{
   width: 100%;          /* Ocupa todo el ancho del contenedor */
   text-align: center; 
   font-size: 3rem;
   color:var(--color-dark-blue);
   padding-top: 30px;
}