.modalView {
  overflow: auto;
  background: #fff;
  padding: 1rem;
  width: 700px;
}

.exitIcon {
  display: flex;
  justify-content: flex-end;
}

.containerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 8px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.labelSintomaHour{ 
  display: flex;
  
}
.link {
  color:#68b3e0;
}

.styleArrow {
  font-size: 40px;
  color: #68b3e0;
  font-weight: bold;
}

.arrowsMonth {
  display: flex;
  align-items: center;
  padding: 0 0 0 0;
  margin: 15px 0px 15px 34px;
  font-weight: bold;
  font-size: 1.5em;
  font-family: 'Montserrat', sans-serif !important;
  color: #68b3e0;
}

.iconDay {
  display: none;
}

.tooltipTitle {
  display: flex;
  flex-direction: row;
  justify-content: start;
  color: #f47105;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold;
  text-transform: capitalize;
  word-break: break-word !important;
  margin: 10px 0 5px 5px;
}

.toolTipRow {
  display: flex;
  flex-direction: row;
  justify-content: start;
  color: black;
  font-size: large;
  margin-bottom: 10px;
  margin-left: 4px;
}

.toolTipBold {
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  margin-right: 12px;
  white-space: nowrap;
}

.toolTipText {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #515151;
  
 
}

.buttonBox {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  margin-top: auto;
}

/* Clases no coincidentes, únicas para este archivo */

/* Estilo de la fila de días */
.weekDay {
  padding: 0 0 0 0;
  display: flex;
  flex-direction: row !important;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em;
  color: #68b3e0;
  font-family: 'Poppins', sans-serif !important;
  margin: 10px 0 0;
}

.dayType {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
}

.monthName {
  color: #68b3e0;
}

.month {
  padding: 0 0 0 0;
  margin: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.5em;
  font-family: 'Poppins', sans-serif !important;
}

.iconDayBall {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #68b3e0;
}

.arrowsMonth > button:first-child {
  margin-right: 5px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.arrowsMonth > button:last-child {
  margin-left: 5px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

.containerRowMobile {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  border-top: 1px solid #e2e1e1;
  padding-top: 10px;
}

.horizontalLine {
  height: 2px;
  width: 40%;
  display: flex;
  flex-direction: row;
  background-color: #f18b24;
  border: none;
  justify-content: left;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 12px;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 380px;
  border: 4px solid #dbdadacc;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 3px #dbdadacc;
  max-height: 400px;
}

.tooltipContent {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f18b24 #ffffff;
}

.imgTraining {
  height: 60px;
  width: 60px;
  border-radius: 30px;
}

.puntitos {
  font-size: 36px;
  font-weight: bold;
  color: #666666;
  padding: 0;
}

.img {
  height: 10px;
  margin-right: 3px;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}

.crisisNum {
  font-size: 1.5rem;
  font-weight: normal;
  padding: 0 !important;
  margin: 0 5px 0 0 !important;
}

.empty {
  height: 16px;
  font-family: 'Poppins', sans-serif !important;
}

.tabRow {
  width: 100% !important;
}

.dateName {
  max-width: 50px;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0.8em;
  font-family: 'Poppins', sans-serif !important;
}

.tabHeader {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.MuiTableCell-head {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tabCell {
  border-bottom: 1px solid lightgrey !important;
  height: 50px;
  padding: 0 !important;
  width: 50px !important;
  font-family: 'Poppins', sans-serif !important;
}

/* Resolución para dispositivos móviles */
@media (max-width: 599px) {
  .modalView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .containerRow {
    padding: 0;
    margin-bottom: 0;
  }

  .weekDay {
    font-size: 1rem;
  }

  .tabCell {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    border: 1px solid lightgrey !important;
    height: 70px;
    vertical-align: top !important;
  }

  .dataDivNumber {
    margin-top: 0 !important;
    padding-top: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .date {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 16px;
    color: #504f4f;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 0 !important;
    z-index: 1;
  }

  .orangePoint {
    background-color: #f18b24;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin: 0;
    z-index: 2;
  }

  .arrowsMonth {
    display: flex;
    align-items: center;
    padding: 0 0 0 0;
    margin: 15px 0 15px 0;
    font-weight: bold;
    font-size: 1.1em;
    font-family: 'Montserrat', sans-serif !important;
    color: #68b3e0;
  }

  .styleArrow {
    color: #68b3e0;
  }

  .img {
    height: 10px;
    margin-right: 3px;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }

  .empty {
    height: 16px;
    font-family: 'Poppins', sans-serif !important;
  }

  .tabRow {
    width: 100% !important;
  }

  .dateName {
    max-width: 50px;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0.8em;
    font-family: 'Poppins', sans-serif !important;
  }

  .tabHeader {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MuiTableCell-head {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tabCell {
    border-bottom: 1px solid lightgrey !important;
    height: 50px;
    padding: 0 !important;
    width: 50px !important;
    font-family: 'Poppins', sans-serif !important;
  }

  .tooltipTitle {
    display: flex;
    flex-direction: row;
    justify-content: start;
    color: #f47105;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold;
    text-transform: capitalize;
    word-break: break-word !important;
    margin: 10px;
  }

  .buttonBox {
    margin-bottom: 0.5rem;
  }
}
