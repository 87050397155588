/* .containerInside {
  flex-direction: column;
  width: 1000px;
  display: flex;
  background-color: var(--color-white);
  padding: 1.5rem;
}

.agoraContainer {
  display: flex;
  justify-content: center;
  padding-top: 13%;
} */



.agoraContainer {
  display: flex;
  justify-content: center;
  padding-top: 13%;
  font-family: 'Arial', sans-serif; /* Define fuente global */
  
  visibility: visible; /* Garantiza que todo es visible */
}

.containerInside {
  display: flex;
  flex-direction: column;
  width: 1000px;
  background-color: var(--color-white);
  padding: 1.5rem;
  visibility: visible; /* Asegura que no sea ocultado */
}

.printPDF {
  position: relative; /* Asegura una posición fija */
  width: 100%;
  height: 100%;
  
}



.buttons {
  display: flex;
  justify-content: space-between;
  width: 38%;
  margin-left: 31%;
  padding-top: 3%;
}

.buttonsCancel {
  display: flex;
  justify-content: space-between;
  width: 38%;
  margin-left: 40%;
  padding-top: 3%;
}

.alert {
  height: 90px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 3.5%;
}

.newNotification{
    border: solid 1px #68b3e0;
    border-radius: 10px;
}
