.avatarWrapper {
  margin: auto 5px;
}

.conversationBlock {
  margin: auto 5px;
  width: 100%;

}

.conversationName {
  margin: 2px auto 1px auto;
  font-size: 20px;
}

.conversationDescription {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 14px;
}

.converstationDate {
  margin: 5px  10px;
  font-weight: normal;
  font-size: 14px;
}

.conversationBody {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
}

.lastMessage {
  margin-top: 0;
}

.conversation {
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  border : 1px solid #68b3e0;
  background: #fff;
  padding: 5px;
  box-shadow: 0 2.5px 5px #d8d8d8;
  box-sizing: border-box; 
}

.activeConversation {
  background-color: #d7f0ff;
}

.conversationBadge {
  margin: auto 5px;
}
