.sendMessageBox {
  position: absolute;
  bottom: 104px;
  left: 0;
  right: 0;
  background: white;
  z-index: 1;
}

.headerContainer {
  background-color: #375980 !important;
}

.messengerContainer {
  display: flex;
  gap: 1rem;
  margin: 0 1rem;
}

.messagesContainer {
  min-height: 40vh;
  max-height: 42vh;
}

.chatList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 35vh;
  margin: 15px 0;
  padding: 0;
  overflow-y: scroll;
  position: relative;
  margin-right: -8px;
}

.chatList::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

.chatList::-webkit-scrollbar-track {
  background: transparent;
}

.chatList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  transition: background-color 0.5s ease;
}

.chatList:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.chatList::-webkit-scrollbar-button {
  display: none;
}

/* Rest of the CSS remains the same */
.searcherContainer {
  column-gap: 1rem;
}

.modalNewConversation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.labelCreateOrSelectConversation {
  text-align: center;
}

.conversationWidget {
  background-color: white !important;
  padding: 20px !important;
  width: 528px;
  min-width: 40%;
  border-radius: 10px;
  box-shadow: 0 2.5px 5px #d8d8d8;
}

.messengerWidget {
  padding: 0 !important;
  width: 60%;
}

.emptyConversation {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  gap: 0.5rem;
  background-color: transparent;
  border: 2px solid #d8d8d8;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.labelCreateOrSelectConversation {
  text-align: center;
  margin: 0;
  max-width: 200px;
  font-weight: bold;
}

.imgCreateOrSelectConversation {
  width: 75px;
  height: 75px;
}

.orangeUnderscore {
  width: 75px;
  height: 2px;
  background: #f37e31;
}

.blueLine {
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 3px solid var(--color-light-blue);
}

.displayNone {
  display: none;
}

.newConversationBox {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 900px) {
  .messengerContainer {
    flex-direction: column;
  }
  
  .conversationWidget {
    width: auto !important;
    margin-bottom: 20px;
  }
  
  .messengerWidget {
    width: auto !important;
  }
  
  .emptyConversation {
    width: auto !important;
    height: 70px;
    margin-right: 0 !important;
  }
}

@media (max-width: 599px) {
  .conversationWidget {
    background-color: white !important;
    padding: 0 !important;
    margin: 0;
    width: auto;
    box-shadow: none;
  }
  
  .backButtonContaienr {
    background-color: white;
    margin: 0 0 0 10px;
  }
  
  .messengerContainer {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 20px;
    background-color: white;
  }
  
  .messengerWidget {
    margin: 0;
  }
  
  .emptyConversation {
    display: none;
  }
}