.articleWrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex block;
  flex-direction: column;
  row-gap: 1rem;
}

.title {
  font-size: 1.3rem;
  text-align: center;
  border-bottom: 3px solid #67b2de;
  padding-bottom: 1.2rem;
  overflow: hidden;
}

.tagLink {
  font-size: 0.9rem;
  text-decoration: none;
  color: #0062a5;
  margin-left: -25px;
}

.tags {
  font-size: 0.9rem;
  border-bottom: 1px solid darkgray;
  padding-bottom: 0.3rem;
  margin-top: -0.5rem;
}

.content {
  text-align: justify;
  font-size: 1.2rem;
}

.files {
  border-bottom: 1px solid darkgray;
}

.backButton {
  display: flex;
  justify-content: flex-end;
}

.thumbnail {
  object-fit: scale-down;
  max-height: 400px;
  height: auto;
  width: 95%;
  margin: auto;
}

.thumbnailIcon {
  max-height: 400px;
  height: auto;
  width: 95%;
  margin: auto;
}

.creator {
  border-top: 3px solid #f88c24;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 3px solid #f88c24;
}

.descarga {
    margin: auto;
    button{
        width: 250px;
    }
}
